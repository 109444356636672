export enum StageNameConstant {
  CourseCompleted = 'Course Completed',
  ProjectRejected = 'Project Rejected',
  CourseRejected = 'Course Rejected',
  DateConfirmed = 'Date Confirmed',
  CoursesStandby = 'Courses to be organized on standby',
  DateProposed = 'Date Proposed',
  DateRequested = 'Date Requested',
}

export enum socketProject {
  JOIN_PROJECT_MANAGEMENT_BOARD = 'join-project-management-board',
  JOIN_COURSE_MANAGEMENT_BOARD = 'join-course-management-board',
  UPDATE_PROJECT_CARD_STAGE = 'update-project-card-stage',
  UPDATE_PROJECT_CARD_ORDER = 'update-project-card-order',
  UPDATE_COURSE_CARD_STAGE = 'update-course-card-stage',
  UPDATE_COURSE_CARD_ORDER = 'update-course-card-order',
}
